import { callApi } from "."


export const getAllAgents = async ({ userId }) => {
    return await callApi({
        method: "GET",
        url: `/agent/all?userId=${userId}`,
        serializerFunc: (response: any) => response.data
    })
}
export const createAgent = async ({ userId, name }) => {
    return await callApi({
        method: "POST",
        url: '/agent',
        requestBody: { userId, name },
        serializerFunc: (response: any) => response.data
    })
}
export const getAgentDashboardMetrics = async ({ userId }) => {
    return await callApi({
        method: "GET",
        url: `/agent/metrics?userId=${userId}`,
        serializerFunc: (response: any) => response.data
    })    
}

export const getAgentDetails = async ({ userId, agentId }) => {
    return await callApi({
      method: "GET",
      url: `/agent?userId=${userId}&agentId=${agentId}`,
      serializerFunc: (response: any) => response.data,
    });
}

export const getAverageScriptScores = async ({ userId }) => {
    return await callApi({
      method: "GET",
      url: `/agent/average-script-scores?userId=${userId}`,
      serializerFunc: (response: any) => response.data,
    });
}