import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import "./AllCalls.css";
import { Link } from "react-router-dom";
import { getAllCalls } from "../../api/call.api";
import { RoutePath } from "../../routes";
import { CompletedTag, ProcessingTag, WaitingTag } from "./statusTags";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface CallData {
  aId: string;
  agentId: string;
  timestamp: string;
  callId: string;
  status: "completed" | "processing" | "waiting";
}

const AllCalls: React.FC = () => {
  const [data, setData] = useState<CallData[]>([]);

  const userId = useSelector((state: RootState) => state.persisted.user.value)._id;

  useEffect(() => {
    getAllCalls({userId}).then((data) => {
      const calls = data.map((call: any) => {
        return {
          agentId: call.agentId,
          aId: call.aId,
          timestamp: new Date(call.timestamp ?? Date.now()).toLocaleString(),
          callId: call.callId,
          status: call.status,
        };
      });
      setData(calls.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
    });
  }, []);

  const columns: ColumnsType<CallData> = [
    {
      title: "Agent ID",
      dataIndex: "aId",
      key: "aId",
    },
    {
      title: "Call Date & Time",
      dataIndex: "timestamp",
      key: "timestamp",
    },
    {
      title: "Processing Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        if (status === "completed") {
          return <CompletedTag />;
          }
        else if (status === "processing") {
        return <ProcessingTag />;
        }
        else if (status === "waiting") {
            return <WaitingTag />;
        }
      },
    },
    {
      dataIndex: "callId",
      key: "callId",
        render: (callId: string, record: CallData) => {
          if (record.status === "completed") {
            return (
              <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`}>
                View Call
              </Link>
            );
            }
          else {
            return <span></span>;
          }
      },
    },
  ];

  return (
    <div className="main-content">
      <h2>All Calls</h2>
      <Table columns={columns} dataSource={data} rowKey="agentId" />
    </div>
  );
};

export default AllCalls;
