import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import './ScriptNonAdherenceCalls.css'
import { Link } from 'react-router-dom';
import { getAllCalls } from '../../api/call.api';
import { RoutePath } from '../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface CallData {
  agentId: string;
  callDateTime: string;
  scriptAdherenceScore: number;
  callId: string;
}

const ScriptNonAdherenceCalls: React.FC = () => {
  const [data, setData] = useState<CallData[]>([]);
  
  const userId = useSelector((state: RootState) => state.persisted.user.value)._id;

    useEffect(() => {

      getAllCalls({ userId, scriptAdherence:true}).then((data) => {
            const calls = data.map((call: any) => {
                return {
                  agentId: call.aId,
                  callDateTime: new Date(call.timestamp?? Date.now()).toLocaleString(),
                  scriptAdherenceScore: call.scriptScore,
                  callId: call.callId,
                };
            })
            setData(calls.sort((a, b) => new Date(b.callDateTime).getTime() - new Date(a.callDateTime).getTime()));
        })
    }, []);

    const columns: ColumnsType<CallData> = [
      {
        title: "Agent ID",
        dataIndex: "agentId",
        key: "agentId",
      },
      {
        title: "Call Date & Time",
        dataIndex: "callDateTime",
        key: "callDateTime",
      },
      {
        title: "Script Adherence Score",
        dataIndex: "scriptAdherenceScore",
        key: "scriptAdherenceScore",
        render: (score: number) => {
          const scoreClass =
            score >= 90 ? "good" : score >= 70 ? "moderate" : "bad";
          return <div className={`score-row ${scoreClass}`}>{score}/10</div>;
        },
      },
      {
        dataIndex: "callId",
        key: "callId",
        render: (callId: string) => (
          <Link
            to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`}
            className="btn btn-outline-secondary btn-sm"
          >
            View
          </Link>
        ),
      },
    ];

    return (
        <div className="main-content">
            <h2>Script Non-Adherence Calls</h2>
            <Table columns={columns} dataSource={data} rowKey="agentId" />
        </div>
    );
};

export default ScriptNonAdherenceCalls;
