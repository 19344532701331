import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link } from 'react-router-dom';
import { getAllCalls } from '../../api/call.api';
import { RoutePath } from '../../routes';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface CallData {
    agentId: string;
    callDateTime: string;
    duration: string;
    callId: string;
}

const HighDurationCalls = () => {
  const [data, setData] = useState<CallData[]>([]);
  
    const userId = useSelector((state: RootState) => state.persisted.user.value)._id;

    useEffect(() => {
      getAllCalls({ userId, highDuration: true }).then((data) => {
          const calls = data.map((call: any) => {
            return {
                agentId: call.aId,
                callDateTime: new Date(call.timestamp ?? Date.now()).toLocaleString(),
                duration: `${Math.floor(call.duration / 60)}m ${Math.round(call.duration % 60)}s`,
                callId: call.callId,
            };
        });
        setData(calls.sort((a, b) => new Date(b.callDateTime).getTime() - new Date(a.callDateTime).getTime()));
      });
    }, []);

    const columns: ColumnsType<CallData> = [
      {
        title: "Agent ID",
        dataIndex: "agentId",
        key: "agentId",
      },
      {
        title: "Call Date & Time",
        dataIndex: "callDateTime",
        key: "callDateTime",
      },
      {
        title: "Call Duration",
        dataIndex: "duration",
        key: "duration",
        render: (duration: string) => {
          return <div>{duration}</div>;
        },
      },
      {
        dataIndex: "callId",
        key: "callId",
        render: (callId:string) => (
          <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`} className="btn btn-outline-secondary btn-sm">
            View
          </Link>
        ),
      },
    ];


    return (
      <div className="main-content">
            <h2>High Duration Calls</h2>
            <p>Calls with duration more than 5 minutes are flagged here</p>
        <Table columns={columns} dataSource={data} rowKey="agentId" />
      </div>
    );
}

export default HighDurationCalls;