"use client"
import React, { useEffect } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';  // TODO: this package has been deprecated, consider using react google oauth
import { gapi } from 'gapi-script';
import toast from 'react-hot-toast';
import { User } from '../../models/user.model';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { setUser, setUserToken } from '../../redux/slices/user';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routes';
import { createOrFindUser } from '../../api/user.api';

interface LoginDataResponse {
  user: {
    _id: string;
    name: string;
    emailId: string;
    usageLimit: number;
  },
  message: string;
}

const GoogleAuth: React.FC = () => {

    const navigate = useNavigate();

    const CLIENT_ID = process.env.REACT_APP_GCP_CLIENT_ID;
    const API_KEY = process.env.REACT_APP_GCP_CLIENT_SECRET;
    const SCOPES = 'openid email profile'

    const userToken = useSelector((state: RootState) => state.persisted.user.value.token);
    const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (userToken) {
      navigate(RoutePath.DASHBOARD);
    }
  }, [userToken]);

    useEffect(() => {
        function start() {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            });
        }
        gapi.load('client:auth2', start);
    }, [API_KEY, CLIENT_ID, SCOPES]);

    const onSuccess = async (response: any) => {
      const toastId = toast.loading("Signing you in...");
      const loginData: LoginDataResponse = await createOrFindUser({
        name: response.profileObj.name,
        emailId: response.profileObj.email,
      });
      const u: User = {
        _id: loginData.user._id,
        token: response.accessToken,
        email: response.profileObj.email,
        name: response.profileObj.name,
        photoUrl: response.profileObj.imageUrl,
        usageLimit: loginData.user.usageLimit,
      };
      dispatch(setUser(u));
      toast.success("Signed in successfully!", { id: toastId });
      navigate(RoutePath.DASHBOARD);  
    };
    const onFailure = (response: any) => {
    };
    const onLogoutSuccess = () => {
        dispatch(setUserToken(''))
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "100vw",
          backgroundColor: "#000",
          color: "#fff",
          height: "100vh",
          overflowX: "hidden",
        }}
      >
        <div className="auth-container">
          {!userToken && (
            <div className="login-container">
              <GoogleLogin
                clientId={CLIENT_ID as string}
                onSuccess={async (response: any) => await onSuccess(response)}
                onFailure={onFailure}
                cookiePolicy="single_host_origin"
                // responseType='code'
                accessType="online" // Change to 'offline' when making auth more robust, i.e get refresh token and auth from server
                prompt="consent"
              >
                <span>Sign in with Google</span>
              </GoogleLogin>
            </div>
          )}
          {userToken && (
            <GoogleLogout
              clientId={CLIENT_ID as string}
              onLogoutSuccess={onLogoutSuccess}
            />
          )}
        </div>
        <div>
          <p>
            Login to Recontact with your Google account
          </p>
        </div>
      </div>
    );

}
export default GoogleAuth
