import React, {useEffect, useRef, useState} from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, UploadProps, Select, Button, Divider, Space, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import AWS from 'aws-sdk';
import { createAgent, getAllAgents } from "../../api/agent.api";
import "./UploadCalls.css";
import { processCall } from "../../api/call.api";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import type { InputRef } from "antd";


const { Dragger } = Upload;

const config = {
  bucketName: process.env.REACT_APP_AWS_BUCKET_NAME,
  dirName: process.env.REACT_APP_S3_DIR_NAME,
  region: process.env.REACT_APP_AWS_REGION_NAME,
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
};

interface SelectOption {
  value: string;
  label: string;
}

const UploadCalls: React.FC = () => {

    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    const [selectedLanguage, setSelectedLanguage] = useState<string>("")
    const [agentSelectOptions, setAgentSelectOptions] = useState<SelectOption[]>([])
    const [selectedAgentId, setSelectedAgentId] = useState<any>(null)
    const [newAgentName, setNewAgentName] = useState<string>("")
    
    const inputRef = useRef<InputRef>(null);
    
    const userId = useSelector((state: RootState) => state.persisted.user.value)._id;
    const usageLimit = useSelector((state: RootState) => state.persisted.user.value).usageLimit
    
    useEffect(()=>{
      getAllAgents({userId}).then((data) => {
        const agents = data.map((agent: any) => {
          return { aId: agent.aId, name: agent.name, id: agent._id }
        })
        setAgentSelectOptions(agents.map((agent: any) => ({ value: agent.id, label: agent.name })))
      })
    },[])
  
    const handleFileUpload = async (file: any, config: any) => {
      const s3 = new AWS.S3({
        accessKeyId: config.accessKeyId,
        secretAccessKey: config.secretAccessKey,
        region: config.region,
        httpOptions: {
          timeout: 300000,
        },
      });

      const fileName = `${uuidv4()}.${file.name.split(".").pop()}`;
      const params = {
        Bucket: config.bucketName,
        Key: `${config.dirName}/${fileName}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params)
          .send(async (err: any, data: any) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
      });
    };


  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList:false,
    beforeUpload: async (file: any) => {
      try {
        const toastId = toast.loading(`Uploading file '${file.name}'...`);
        const data: any = await handleFileUpload(file, config as any);
        toast.success(`File '${file.name}' uploaded successfully.`, { id: toastId });
        setUploadedFiles((uploadedFiles) => [
          ...uploadedFiles,
          {
            url: data.Location,
            name: file.name,
          },
        ]);
      } catch (error: any) {
        message.error(`Upload failed: ${error.message}.`);
      }
      return false;
    },
  };

  const handleProcessCalls = async () => {
    // call api for all objects in uploadedFiles
    try {
      message.info("Adding calls to queue for processing...");
      for (const file of uploadedFiles) {
        // if (usageLimit === 0) {
        //   toast.error("You have reached your usage limit. Please reach out to founders@recontact.world to increase your limit.");
        //   return false;
        // }
        await processCall({ userId, url: file.url, agentId: selectedAgentId, language: selectedLanguage });
      }
      message.success("Calls added to queue for processing successfully.");
    }
    catch (error: any) {
      message.error(`Failed to add calls to queue for processing: ${error.message}.`);
    }
    finally {
      setUploadedFiles([]);
    }
  }

  const handleAddNewAgent = async () => {
    if(newAgentName === ""){
      return
    }
    const toastId = toast.loading(`Creating agent '${newAgentName}'...`);
    try {
      const agent = await createAgent({ userId, name: newAgentName });
      setAgentSelectOptions([...agentSelectOptions, { value: agent._id, label: agent.name }]);
      setNewAgentName("");
      toast.success(`Agent '${agent.name}' added successfully.`, { id: toastId });
    } catch (error: any) {
      toast.error(`Failed to add agent: ${error.message}`, { id: toastId });
    }
  }

  return (
    <div className="main-content">
      <h2>Upload Calls</h2>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag files to this area to upload
        </p>
      </Dragger>
      {uploadedFiles.length > 0 && (
        <div className="process-files-grid">
          <div className="uploaded-files-box">
            <h3>Uploaded Files</h3>
            <div>
              {uploadedFiles.map((file, index) => (
                <div key={index}>
                  <a href={file.url} target="_blank" rel="noreferrer">
                    {file.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="operations-box">
            <Select
              style={{ width: 300 }}
              placeholder="Select Agent"
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Input
                      placeholder="Enter agent name"
                      ref={inputRef}
                      onChange={(e) => setNewAgentName(e.target.value)}
                      onKeyDown={(e) => e.stopPropagation()}
                      value={newAgentName}
                    />
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => handleAddNewAgent()}
                    >
                      Create Agent
                    </Button>
                  </Space>
                </>
              )}
              options={agentSelectOptions}
              onChange={(value) => {
                setSelectedAgentId(value);
              }}
            />
            <Select
              placeholder="Select Language"
              style={{ width: 200, marginLeft: 10 }}
              optionFilterProp="label"
              options={[
                { value: "en", label: "English" },
                { value: "hi", label: "Hindi" },
              ]}
              onChange={(value) => {
                setSelectedLanguage(value);
              }}
            />
            <br />
            <Button
              type="primary"
              style={{ marginTop: 10 }}
              disabled={!selectedAgentId || !selectedLanguage}
              onClick={handleProcessCalls}
            >
              Process Calls
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadCalls;
