import axios, { AxiosRequestConfig } from "axios";

export const callApi = async ({
  method,
  url,
  requestBody = {},
  params = {},
  serializerFunc,
}: any) => {
  try {
    const requestConfig: AxiosRequestConfig = {
      method,
      url,
      data: requestBody,
      headers: {
        "Content-Type": "application/json",
        "x-access-token": process.env.REACT_APP_API_KEY,
      },
      params,
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 1200000,
    };
    const responseData = await axios.request(requestConfig);
    const serializedRes = await serializerFunc(responseData);
    return serializedRes;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
