import React, { useEffect, useState, useRef } from 'react';
import { Layout, Card, Table, Spin } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Chart from 'chart.js/auto';
import './AgentReview.css'; // Custom styles
import { Link } from 'react-router-dom';
import { RoutePath } from '../../routes';
import { getAgentDetails } from '../../api/agent.api';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface AgentDetails {
  agentId: string;
  aId: string;
  name: string;
}

interface AgentCall {
  callId: string;
  customerId: string;
  cId: string;
  callScore: number;
  timestamp: number;
  duration: number;
  isAbuseDetected: boolean;
}

const AgentReview: React.FC = () => {
    const agentId = new URL(window.location.href).searchParams.get("agentId");
    if (!agentId) {
      window.location.href = RoutePath.HOME;
    }
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [agentDetails, setAgentDetails] = useState<AgentDetails | null>(null);
    const [agentCalls, setAgentCalls] = useState<AgentCall[] | null>(null);
    const tonalityChartRef = useRef<Chart | null>(null);
    const scriptAdherenceChartRef = useRef<Chart | null>(null);

    const userId = useSelector((state: RootState) => state.persisted.user.value)._id;

    useEffect(() => {
        
        getAgentDetails({ userId, agentId })
          .then((data) => {
              const agent = {
                    agentId: data.agentId,
                    aId: data.aId,
                    name: data.name,
                };
              setAgentDetails(agent);
              setAgentCalls(data.calls.sort((a, b) => b.timestamp - a.timestamp));
              setIsLoading(false)
          });

        // const tonalityCtx = (document.getElementById('tonalityChart') as HTMLCanvasElement).getContext('2d');
        // const scriptAdherenceCtx = (document.getElementById('scriptAdherenceChart') as HTMLCanvasElement).getContext('2d');

        // const tonalityData = {
        //     labels: ['June 30', 'July 1', 'July 2', 'July 3', 'July 4', 'July 5'],
        //     datasets: [{
        //         label: 'Tonality Score',
        //         data: [15, 88, 22, 65, 30, 82],
        //         borderColor: 'rgb(75, 192, 192)',
        //         tension: 0.1,
        //         fill: false
        //     }]
        // };

        // const scriptAdherenceData = {
        //     labels: ['June 30', 'July 1', 'July 2', 'July 3', 'July 4', 'July 5'],
        //     datasets: [{
        //         label: 'Script Adherence Score',
        //         data: [48, 85, 57, 95, 19, 21],
        //         borderColor: 'rgb(255, 99, 132)',
        //         tension: 0.1,
        //         fill: false
        //     }]
        // };

        // if (tonalityChartRef.current) {
        //     tonalityChartRef.current.destroy();
        // }
        // tonalityChartRef.current = new Chart((tonalityCtx as any), {
        //     type: 'line',
        //     data: tonalityData,
        //     options: {
        //         responsive: true,
        //         scales: {
        //             y: {
        //                 beginAtZero: true,
        //                 max: 100
        //             }
        //         }
        //     }
        // });

        // if (scriptAdherenceChartRef.current) {
        //     scriptAdherenceChartRef.current.destroy();
        // }
        // scriptAdherenceChartRef.current = new Chart((scriptAdherenceCtx as any), {
        //     type: 'line',
        //     data: scriptAdherenceData,
        //     options: {
        //         responsive: true,
        //         scales: {
        //             y: {
        //                 beginAtZero: true,
        //                 max: 100
        //             }
        //         }
        //     }
        // });

        // return () => {
        //     tonalityChartRef.current?.destroy();
        //     scriptAdherenceChartRef.current?.destroy();
        // };
    }, []);

    const columns: ColumnsType<AgentCall> = [
        {
            title: 'Customer ID',
            dataIndex: 'cId',
            key: 'cId',
        },
        {
            title: 'Date and Time',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp: number) => {
                return <div>{new Date(timestamp).toLocaleString()}</div>;
            },
        },
        {
            title: 'Call Score',
            dataIndex: 'callScore',
            key: 'callScore',
            render: (callScore: number) => {
                return <div>{callScore}/10</div>;
            },
        },
        {
            title: 'Abuse Detected',
            dataIndex: 'isAbuseDetected',
            key: 'isAbuseDetected',
            render: (isAbuseDetected: boolean) => {
                return <div>{isAbuseDetected? "Yes":"No"}</div>;
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (duration: number) => {
                return <div>{`${Math.floor(duration / 60)}m ${Math.round(duration % 60)}s`}</div>;
            },
        },
       {
        dataIndex: "callId",
        key: "callId",
        render: (callId:string) => (
          <Link to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`} className="btn btn-outline-secondary btn-sm">
            View
          </Link>
        ),
      },
    ];

    return (
        <Layout.Content className="main-content">
            <div className="row">
                <div className="col-12">
                    <h2 className="py-2">Agent Review</h2>
                </div>
            </div>
            {isLoading ? (
            <Spin/>
            ) : (<div>
                    <div className="row" style={{ display: 'flex', gap: '1rem', justifyContent: 'flex-start' }}>
                        <div className="col-md-4">
                            <Card className="agent-card">
                                <h5>{agentDetails.name}</h5>
                                <p>Position: Senior Agent</p>
                                <p>Agent ID: {agentDetails.aId}</p>
                                <p className="card-title">No. of Calls: <span className="card-text" id="totalCalls">{agentCalls.length}</span></p>
                            </Card>
                        </div>
                        {/* <div className="col-md-4">
                            <Card className="metric-card">
                                <div className="card-body">
                                    <h5 className="card-title">Tonality Score</h5>
                                    <canvas style={{ height: '150px', width: '100%' }} id="tonalityChart"></canvas>
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card className="metric-card">
                                <div className="card-body">
                                    <h5 className="card-title">Script Adherence Score</h5>
                                    <canvas style={{ height: '150px', width: '100%' }} id="scriptAdherenceChart"></canvas>
                                </div>
                            </Card>
                        </div> */}
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h3 className="py-2">Call History</h3>
                            <Table columns={columns} dataSource={agentCalls} rowKey="callId" />
                        </div>
                    </div>
                </div>)}
        </Layout.Content>
    );
};

export default AgentReview;
