import { useEffect, useState } from "react";
import { getAllCalls } from "../../api/call.api";
import { Link } from "react-router-dom";
import { RoutePath } from "../../routes";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Table, Tabs } from "antd";
import { ColumnsType } from "antd/es/table";
import type { TabsProps } from "antd";

interface CallData {
  callId: string;
  agentId: string;
  customerId: string;
  aId: string;
  cId: string;
  callDateTime: string;
  isWilling: boolean | null;
  ptpScore?: number;
  ptpResult?: string;
  noPtpType?: string;
}

enum PtPTypeKeys {
  HIGH = "PTP1 (High Confidence)",
  MEDIUM = "PTP2 (Medium Confidence)",
  LOW = "PTP3 (Low Confidence)",
  OTHER = "No PTP (No Confidence)",
}

enum NoPtPTypeKeys {
  SITUATIONAL_DEFAULTER = "SITUATIONAL_DEFAULTER",
  DENYING_LOAN = "DENYING_LOAN",
  PURPOSEFUL_DEFAULTER = "PURPOSEFUL_DEFAULTER",
  REQUEST_FOR_TIME = "REQUEST_FOR_TIME",
}

const PaymentIntent = () => {
    const showIsWilling = new URL(window.location.href).searchParams.get("isWilling");
    if (!showIsWilling) {
      window.location.href = RoutePath.HOME;
    }

  const [callData, setCallData] = useState<CallData[]>([]);
  const [allCallsData, setAllCallsData] = useState<CallData[]>([]);
  
  const userId = useSelector((state: RootState) => state.persisted.user.value)._id;

  useEffect(() => {
      getAllCalls({ userId, paymentIntent: true }).then((data) => {
        const calls = data.map((call: any) => {
          let callObj:CallData =  {
            callId: call.callId,
            agentId: call.agentId,
            customerId: call.customerId,
            aId: call.aId,
            cId: call.cId,
            isWilling: call.isWilling,
            callDateTime: new Date(call.timestamp ?? Date.now()).toLocaleString(),
          };
          if (call.isWilling == true) {
            callObj.ptpScore = call.ptpScore ?? 0;
            callObj.ptpResult = call.ptpResult ?? "No PTP (No Confidence)";
          } else if (call.isWilling == false) {
            callObj.noPtpType = call.noPtpType ?? "PURPOSEFUL_DEFAULTER";
          }
          return callObj;
        });
        const filteredAndSortedCalls = calls.filter((call)=>call.isWilling === (showIsWilling === "true" ? true : (showIsWilling === "false" ? false : null))).sort((a, b) => new Date(b.callDateTime).getTime() - new Date(a.callDateTime).getTime());
        setAllCallsData(filteredAndSortedCalls);
        setCallData(filteredAndSortedCalls.filter((call) => showIsWilling === "true" ? call.ptpResult === PtPTypeKeys.HIGH : (showIsWilling === "false" ? call.noPtpType === NoPtPTypeKeys.SITUATIONAL_DEFAULTER : true)));
      });
  }, [])
    
  const onChange = (key: string) => {
    setCallData(allCallsData.filter((call) => showIsWilling === "true" ? call.ptpResult === key : call.noPtpType === key));
  };

  const columns: ColumnsType<CallData> = [
    {
      title: "Agent ID",
      dataIndex: "aId",
      key: "aId",
    },
    {
      title: "Customer ID",
      dataIndex: "cId",
      key: "cId",
    },
    {
      title: "Call Date & Time",
      dataIndex: "callDateTime",
      key: "callDateTime",
    },
    {
      dataIndex: "callId",
      key: "callId",
      render: (callId: string) => (
        <Link
          to={`${RoutePath.CALL_ANALYSIS}?callId=${callId}`}
          className="btn btn-outline-secondary btn-sm"
        >
          View
        </Link>
      ),
    },
  ];
  
  const ptpItems: TabsProps["items"] = [
    {
      key: PtPTypeKeys.HIGH,
      label: "High Confidence",
      // children: "Content of Tab Pane 1",
    },
    {
      key: PtPTypeKeys.MEDIUM,
      label: "Medium Confidence",
      // children: "Content of Tab Pane 2",
    },
    {
      key: PtPTypeKeys.LOW,
      label: "Low Confidence",
      // children: "Content of Tab Pane 3",
    },
    {
      key: PtPTypeKeys.OTHER,
      label: "Other",
      // children: "Content of Tab Pane 3",
    },
  ];
  
  const noPtpItems: TabsProps["items"] = [
    {
      key: NoPtPTypeKeys.SITUATIONAL_DEFAULTER,
      label: "Situational Defaulters",
      // children: "Content of Tab Pane 1",
    },
    {
      key: NoPtPTypeKeys.PURPOSEFUL_DEFAULTER,
      label: "Purposeful Defaulters",
      // children: "Content of Tab Pane 2",
    },
    {
      key: NoPtPTypeKeys.DENYING_LOAN,
      label: "Customers Denying Loan",
      // children: "Content of Tab Pane 3",
    },
    {
      key: NoPtPTypeKeys.REQUEST_FOR_TIME,
      label: "Customers Requesting Time",
      // children: "Content of Tab Pane 3",
    },
  ];

    return (
      <>
        <div className="main-content">
            <h2>{showIsWilling === "true" ? "Willing" : (showIsWilling === "false" ? "Unwilling" : "Unconclusive")} Payment Intent Calls</h2>
            {showIsWilling !== "null" && (<Tabs defaultActiveKey="1" items={showIsWilling === "true" ? ptpItems : noPtpItems} onChange={onChange} />)}
            <Table columns={columns} dataSource={callData} rowKey="callId" />
        </div>
      </>
    );
}

export default PaymentIntent;